<template>
  <div>
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <button @click="ScreenPrint" class="btn btn-primary mb-2">
          Générer image
        </button>
        <!--begin::Card-->
        <div class="card card-custom" ref="printcontent">
          <div class="card-header flex-wrap border-2 pt-6 pb-0">
            <div class="card-title">
              <h4 class="ml-3 mt-2 mr-2">Ref:</h4>
              <span class="text-success">{{ ad.code }}</span>
            </div>
            <div class="card-body">
              <b class="text-info"
                >Publié(e) le : <span class="text-info"> {{ date }}</span>
              </b>
              <b>
                <b
                  class="col-sm-6 text text-warning"
                  v-if="ad.location === true"
                >
                  A Louer
                </b>
                <b class="col-sm-6 text text-danger" v-else>A Vendre</b>
              </b>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <h4 class="my-1 card-title">Description</h4>
                <dd class="card-text">
                  <p>{{ ad.description }}</p>
                </dd>
              </div>
              <div class="col-md-5">
                <div class="card shadow rounded">
                  <div style="height: 250px;">
                    <hooper group="group1" class="mb-3 h-100 rounded">
                      <slide
                        v-for="(slide, indx) in images"
                        :key="indx"
                        :index="indx"
                      >
                        <img :src="slide.url" class="w-100 h-100" />
                      </slide>
                    </hooper>
                  </div>
                  <hooper group="group1" :settings="hooperSettings">
                    <slide
                      v-for="(slide, indx) in images"
                      :key="indx"
                      :index="indx"
                    >
                      <img :src="slide.url" height="100%" />
                    </slide>
                    <hooper-navigation slot="hooper-addons"></hooper-navigation>
                    <hooper-pagination slot="hooper-addons"></hooper-pagination>
                    <hooper-progress slot="hooper-addons"></hooper-progress>
                  </hooper>
                </div>
              </div>
              <div class="col-md-3">
                <tr class="row">
                  <th class="col-sm-6">Superficie</th>
                  <td class="col-sm-6">{{ ad.superficie }} m²</td>
                </tr>
                <tr class="row">
                  <th class="col-sm-6">Quartier</th>
                  <td class="col-sm-6">{{ ad.quartier.nom }}</td>
                </tr>
                <tr class="row">
                  <th class="col-sm-6">Type de bien</th>
                  <td class="col-sm-6">{{ ad.type.nom }}</td>
                </tr>
                <tr class="row">
                  <th class="col-sm-6">Ville</th>
                  <td class="col-sm-6">{{ ad.ville.nom }}</td>
                </tr>
                <tr class="row">
                  <th class="col-sm-6">Type</th>
                </tr>
              </div>
            </div>
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import ApiService from "@/core/services/api.service";
import {
  Hooper,
  Navigation as HooperNavigation,
  Pagination as HooperPagination,
  Progress as HooperProgress,
  Slide
} from "hooper";

export default {
  name: "PrintPicture",
  data() {
    return {
      initialize: "ad/" + this.$route.params.id,
      ad: [],
      hooperSettings: {
        itemsToShow: 3,
        centerMode: true
      },
      images: []
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    async ScreenPrint() {
      console.log("printing..");
      const el = this.$refs.printcontent;

      const options = {
        type: "dataURL"
      };
      const printCanvas = await html2canvas(el, options);
      const link = document.createElement("a");
      link.setAttribute("download", "download.png");
      link.setAttribute(
        "href",
        printCanvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream")
      );
      link.click();

      console.log("done");
    },

    reloadData() {
      let vm = this;
      ApiService.get(this.initialize).then(function(response) {
        vm.ad = response.data.annonce;
        vm.images = response.data.annonce.images;
      });
    }
  },
  computed: {
    date: function formatDate() {
      let d = new Date(this.ad.created_at),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [day, month, year].join("-");
    }
  },
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination,
    HooperProgress
  }
};
</script>

<style scoped></style>
